import * as React from "react"

import Layout from "../components/Layout"
import { ProductLayout } from "../components/ProductLayout"
import Seo from "../components/seo"
import useStoryblok from "../utils/storyblok"

const Product = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  return (
    <Layout location={location} sidebarEnabled={true}>
      <Seo title="Product" />
      <ProductLayout blok={story.content} enableBackAction={true} />
    </Layout>
  )
}

export default Product
